export const PAGESIZE = 10;
export const BATCHSIZE = 100;

//Enable/disable multipart upload. default is false.
export const MULTIPART_UPLOAD = true;

export const FOTA_SERVICE = 'awsfota-webservices';
export const DEVICE_FOTA_SERVICE = 'awsfota-deviceservices';

//w601- develop configurations
//export const BASE_URL = 'http://services-w601dev.mmc-solution.com';
//export const BUCKETNAME = 'mmc-w601-dev-awsfota-softwares';

//w601- preprod configurations
export const BASE_URL = 'https://service.w601preprod-mahindrasolution.com';
export const BUCKETNAME = 'mmc-w601-preprod-awsfota-softwares';

//w601- production configurations
// export const BASE_URL = 'https://service.mahindramobilitysolution.com';
//export const BUCKETNAME = 'w601-prod-awsfota-softwares';

//CV- develop configurations
//export const BASE_URL = 'https://service.mcv-mahindrasolution.com';
//export const BUCKETNAME = 'cvdev-awsfota-softwares';

//CV- preprod configurations
//export const BASE_URL = 'https://fota-cvpreprod.mahindramobilitysolution.com';
//export const BUCKETNAME = 'cvpreprod-awsfota-softwares';

//CV- production configurations
// export const BASE_URL = 'https://fotaprod-cv.mahindramobilitysolution.com';
// export const BUCKETNAME = 'cvprod-awsfota-softwares';

export const HOST = BASE_URL+"/"+FOTA_SERVICE;
export const DEVICE_HOST = BASE_URL+"/"+DEVICE_FOTA_SERVICE;

export const BRANDCOLOR={
	base:{
		CNHI:"#482401",
		TATA:"#022748",
		CUMMINS:"#4c010e",
		MAHINDRA:"#e31837"
	},
	sub:{
		CNHI:"#cb1934",
		TATA:"#011629",
		CUMMINS:"#38020a",
		MAHINDRA:"#c1213a"
	},
	menuText:{
		CNHI:"#fff",
		TATA:"#fff",
		CUMMINS:"#fff",
		MAHINDRA:"#e31837"
	},
	menuB:{
		CNHI:"#223540",
		TATA:"#223540",
		CUMMINS:"#223540",
		MAHINDRA:"#c1213a"
	},
	menu:{
		CNHI:"#cc7d15",
		//TATA:"#044079",
		CUMMINS:"#901428",
		MAHINDRA:"#fff"
	},
	hover:{
		CNHI:"#502901",
		TATA:"#042544",
		CUMMINS:"#54010e",
		MAHINDRA:"#9c051c"
	},
	menuHover:{
		MAHINDRA:"#e8d4d4"
	},
	submenuBackground:{
		MAHINDRA:"#353535",
		CNHI:"#FFFFFF",
		TATA:"#FFFFFF",
		CUMMINS:"#FFFFFF"
	},
	submenu:{
		MAHINDRA:"#FFFFFF",
		CNHI:"#000",
		TATA:"#000",
		CUMMINS:"#000"
	},
	pagination:{
		MAHINDRA:"#e31837",
		CNHI:"#6cc788",
		TATA:"#6cc788",
		CUMMINS:"#6cc788"
	},
	widgetHeader:{
		MAHINDRA:"#e31837",
		CNHI:"#2cc4cc",
		TATA:"#2cc4cc",
		CUMMINS:"#2cc4cc",
	},
	wizardProgress:{
		MAHINDRA:"#e31837",
		CNHI:"#1b475f",
		TATA:"#1b475f",
		CUMMINS:"#1b475f"
	},
	wizard:{
		MAHINDRA:"#ea7c7f",
		CNHI:"#3c8dbc",
		TATA:"#3c8dbc",
		CUMMINS:"#3c8dbc"
	},
	breadcrumb:{
		MAHINDRA:"red",
	},
	headerBackground:{
		MAHINDRA:"#fff5f0"
	},
	tableHeader: {
		MAHINDRA:"#f5e7e7"
	}

}
export const BRANDLOGO={
	CNHI:"cnhi-1.png",
	TATA:"tamo-1.png",
	CUMMINS:"cummins-1.png",
	MAHINDRA:"mahindra-logo.png"
}
export const deviceAttributesInfo = {
	"IMEI_Number" : {
		"elementId" : "imei",
		"elementLabel" : "IMEI NUMBER"
	},
	"Part_Number" : {
		"elementId" : "part_number",
		"elementLabel" : "Part NUMBER"
	},
	"Serial_Number" : {
		"SERIAL_NUMBER_RANGE_START" : {
			"elementId" : "serial_number_range_start",
			"elementLabel" : "SERIAL NUMBER RANGE START"
		},
		"SERIAL_NUMBER_RANGE_END" : {
			"elementId" : "serial_number_range_end",
			"elementLabel" : "SERIAL NUMBER RANGE END"
		}
	},
	"Dicom_Version" : {
		"elementId" : "dicom_version",
		"elementLabel" : "Dicom Version"
	},
	"Edge_Algorithm_Type" : {
		"elementId" : "edge_algorithm_type",
		"elementLabel" : "Edge Algorithm Type"
	},
	"Modularity" : {
		"elementId" : "modularity",
		"elementLabel" : "Modularity"
	}
}